<template>
  <div
    v-infinite-scroll="loadMore"
    infinite-scroll-disabled="busy"
    infinite-scroll-distance="10"
  >
    <r-page page-title="Employment">
      <template #page-header>
        <h2 class="page-title">Employee</h2>
        <r-menu-tab :level="2" />
      </template>
      <template #action-bar>
        <div class="level">
          <div class="level-left">
            <b-button
              tag="router-link"
              class="is-command"
              icon-left="plus"
              to="/employment/create"
            >
              New
            </b-button>
          </div>
          <div class="level-right">
            <div class="is-relative">
              <b-button
                icon-left="filter-variant"
                class="btn-filter-people"
                @click="isFilterOpen = !isFilterOpen"
              >
                Filter
              </b-button>
            </div>

            <b-field class="employee-search-container">
              <b-input
                expanded
                v-model="search"
                placeholder="Search"
                type="text"
                @keydown.native.enter="searchIconClick"
                icon-clickable
                icon-right="magnify"
                @icon-right-click="searchIconClick"
                icon-right-clickable
                :loading="isEmployeeGeneralListLoading"
              ></b-input>
            </b-field>
          </div>
        </div>
      </template>

      <template #page-content>
        <div class="filter-card-people" v-if="isFilterOpen">
          <EmploymentFilterComponent @on-toggle-filter="onToggleFilterStatus" />
        </div>

        <div id="table-request-employment">
          <b-table
            :data="employeeGeneralList"
            :per-page="perPage"
            :loading="isEmployeeGeneralListLoading"
            @page-change="onPageChange"
            :show-detail-icon="showDetailIcon"
            ref:table
            hoverable
            narrowed
            backend-sorting
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            checkable
            checkbox-position="left"
            :checked-rows.sync="checkedRows"
            class="employment-directory-table"
            @sort="onSort"
            :sticky-header="stickyHeaders"
          >
            <template>
              <b-table-column
                field="users.first_name"
                label="Employee's Name"
                v-slot="props"
                sortable
                width="18%"
              >
                <div class="columns employment-table-name">
                  <img
                    class="employment-profile-picture"
                    :src="
                      determineProfilePicture(
                        props.row.profilePicture,
                        props.row.gender
                      )
                    "
                  />
                  <span class="is-capitalize">
                    {{ props.row.name }}
                  </span>
                </div>
              </b-table-column>

              <b-table-column
                field="positions.position_name"
                label="Title"
                v-slot="props"
                sortable
                width="12%"
              >
                <span class="is-capitalize">
                  {{ props.row.title }}
                </span>
              </b-table-column>

              <b-table-column
                field="users.work_email"
                label="Email"
                v-slot="props"
                sortable
                width="15%"
              >
                {{ textSubString(props.row.workEmail, '40', '...') }}
              </b-table-column>

              <b-table-column
                field="users.city"
                label="City"
                v-slot="props"
                sortable
                width="10%"
              >
                <span class="is-capitalize">
                  {{ props.row.city }}
                </span>
              </b-table-column>

              <b-table-column
                field="users.province"
                label="Province"
                v-slot="props"
                sortable
                width="10%"
              >
                <span class="is-capitalize">
                  {{ props.row.province }}
                </span>
              </b-table-column>

              <b-table-column
                field="users.is_active"
                label="Status"
                v-slot="props"
                width="10%"
                class="table-is-active"
              >
                <span
                  class="is-capitalize table-is-active__active"
                  v-if="props.row.isActive === '1'"
                >
                  Active
                </span>
                <span class="is-capitalize table-is-active__inactive" v-else>
                  Inactive
                </span>
              </b-table-column>

              <b-table-column
                field="users.annual_leave"
                label="Remaining/Annual Leave"
                v-slot="props"
                sortable
                width="15%"
              >
                <span class="is-capitalize">
                  {{
                    formatAnnualLeave(
                      props.row.annualLeave,
                      props.row.remainingAnnualLeave
                    )
                  }}
                </span>
              </b-table-column>

              <b-table-column
                label="Action"
                v-slot="props"
                width="10%"
                centered
              >
                <router-link :to="`/employment/detail/${props.row.userId}`">
                  <b-icon
                    type="is-primary"
                    icon="eye"
                    custom-size="mdi-18px"
                    class="employment-all-icon"
                  ></b-icon>
                </router-link>
                <router-link :to="`/employment/edit/${props.row.userId}`">
                  <b-icon
                    type="is-primary"
                    icon="pencil"
                    custom-size="mdi-18px"
                    class="employment-all-icon"
                  ></b-icon>
                </router-link>
                <b-icon
                  type="is-primary"
                  icon="archive"
                  custom-size="mdi-18px"
                  class="employment-all-icon"
                  @click.native="archiveWarning(props.row.userId)"
                  v-if="props.row.isActive !== '1'"
                ></b-icon>
                <b-icon
                  type="is-disabled"
                  icon="archive"
                  custom-size="mdi-18px"
                  class="employment-disabled-icon"
                  v-else
                ></b-icon>
              </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>No data found</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
        <div class="has-text-centered" v-if="isLoadMore">Please Wait ...</div>
      </template>
    </r-page>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import EmploymentFilterComponent from './EmploymentFilterComponent'

export default {
  components: { EmploymentFilterComponent },
  computed: {
    ...mapGetters({
      employeeGeneralList: 'employment/getEmployeeGeneralList',
    }),
  },
  data() {
    return {
      checkedRows: [],
      isRowCheckable: true,
      stickyHeaders: true,

      isPaginated: false,
      perPage: 20,
      page: 0,
      lastPage: 0,

      defaultSortOrder: 'asc',
      sortField: 'users.first_name',
      sortOrder: 'asc',
      search: '',

      showDetailIcon: true,

      isEmployeeGeneralListLoading: false,
      isLoadMore: false,

      selectedEmployee: null,

      // filter
      isFilterOpen: false,
      filterStatus: [],
    }
  },
  methods: {
    ...mapActions({
      actionLoadEmployeeGeneralList: 'employment/fetchEmployeeListGeneral',
      archiveEmployee: 'employment/archiveEmployee',
    }),

    ...mapMutations({
      setEmployeeListsGeneral: 'employment/setEmployeeListGeneral',
    }),

    async onToggleFilterStatus(filterStatus) {
      this.filterStatus = filterStatus
      await this.resetState()
    },

    formatAnnualLeave(annualLeave, remaining) {
      let formatAnnualLeave = '-'
      let formatRemaining = '-'
      if (annualLeave) {
        formatAnnualLeave = annualLeave
      }
      if (remaining) {
        formatRemaining = remaining
      }
      return `${formatRemaining}/${formatAnnualLeave}`
    },

    async loadMore() {
      if (this.page < this.lastPage) {
        this.isLoadMore = true
        await this.loadEmployeeGeneralList()
        this.isLoadMore = false
      }
    },

    async searchIconClick() {
      await this.resetState()
    },

    async resetState() {
      this.page = 0
      this.lastPage = 0
      this.setEmployeeListsGeneral([])
      await this.loadEmployeeGeneralList()
    },

    onSort(field, order) {
      this.sortField = field
      this.sortOrder = order
      this.resetState()
    },

    onPageChange(page) {
      this.page = page
      this.loadEmployeeGeneralList()
    },
    getFilter() {
      if (this.filterStatus.length === 2) {
        return null
      } else if (this.filterStatus.includes('active')) {
        return 1
      } else if (this.filterStatus.includes('inactive')) {
        return 0
      }
      return null
    },

    async loadEmployeeGeneralList() {
      this.isEmployeeGeneralListLoading = true
      try {
        const params = {
          perPage: this.perPage,
          page: ++this.page,
          sortField: this.sortField,
          sortOrder: this.sortOrder,
          search: this.search,
          isActive: this.getFilter(),
        }
        const response = await this.actionLoadEmployeeGeneralList(params)

        this.total = response.data.meta.total
        this.lastPage = response.data.meta.lastPage
      } catch (err) {
        console.log(err)
      }
      this.isEmployeeGeneralListLoading = false
    },

    determineProfilePicture(user, gender) {
      if (!user) {
        if (gender === 'M') {
          // male
          return '/images/default-profile-picture-male.png'
        }
        // female
        return '/images/default-profile-picture-female.png'
      }

      return user
    },

    // For Pop-up Archive
    async archiveWarning(id) {
      let res = await this.$swal({
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        customClass: {
          confirmButton: 'button is-primary',
          cancelButton: 'button',
        },
        text: 'Are you sure you want to archive this user?',
      })

      try {
        if (res && res.isConfirmed) {
          let form = {}

          // call vuex method (see employment.module.js)
          await this.archiveEmployee(id, form)

          this.$swal({
            icon: 'success',
            titleText: 'User is successfully archived',
            confirmButtonText: 'Done',
            customClass: {
              confirmButton: 'button is-success',
            },
          })
          this.resetState()
        }
      } catch (e) {
        this.$swal({
          icon: 'error',
          titleText: 'Failed. Please try again!',
          text: e.response.data.message,
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: 'button is-primary',
          },
        })
      }
    },
  },
  async mounted() {
    this.setEmployeeListsGeneral([])
    await this.loadEmployeeGeneralList()
  },
  watch: {
    // This will probably have something to do with checkbox,
    // so I'm not going to delete this code yet
    selectedEmployee(data) {
      this.$router.push(`/employment/detail/${data.userId}`)
    },
  },
}
</script>
