<template>
  <div>
    <h4 class="filter-title">Filter</h4>
    <!-- status -->
    <p class="filter-subtitle">Status</p>
    <div class="filter-section-container">
      <div
        class="filter-item"
        @click="toggleFilterStatus('active')"
        :class="filterStatus.includes('active') ? 'filter-active' : ''"
      >
        Active
      </div>

      <div
        class="filter-item"
        @click="toggleFilterStatus('inactive')"
        :class="filterStatus.includes('inactive') ? 'filter-active' : ''"
      >
        Inactive
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      filterStatus: [],
    }
  },

  methods: {
    async toggleFilterStatus(status) {
      if (this.filterStatus.includes(status)) {
        this.filterStatus = this.filterStatus.filter((s) => s !== status)
      } else {
        this.filterStatus.push(status)
      }
      // emit
      this.$emit('on-toggle-filter', this.filterStatus)
    },
  },
}
</script>

<style scoped></style>
